import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import "twin.macro"

const Contact = () => {
	return <Layout>
		<SEO title="Contact"/>
		<Section id="contact">
			<h1>Contact file.io</h1>
			<p>We would love to hear from you!</p>
			<p>For any questions, comments or concerns, the best way to contact us is by email at <a href="mailto:support@file.io">support@file.io</a></p>
			<p>Our mailing address is: <br/>file.io<br/>P.O. Box 15004<br/>Philadelphia, PA 19130<br/>U.S.A.</p>
			<p>You can reach us by telephone at: (330) 731-1024</p>
		</Section>
	</Layout>
}

export default Contact;